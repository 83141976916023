//==============================================
// Module/inputs, forms, textarea
//==============================================

.int {
  background-color: darken($white, 5%);
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border-radius: 0;
  border: 0;
  border: $b-s-base solid $input-border;
  color: $input-color;
  display: block;
  padding: 10px;
  font-size: $font-size-base;
  line-height: $line-height-base;
  transition: all .3s;
  width: 100%;
  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    border: 0;
    background-color: transparent;
  }
  outline: none;
  &:focus {
    border-color: $color-accent;
  }
}