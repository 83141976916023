//==============================================
// Main page
//==============================================

.main-bg {
  background: url("/assets/img/main-bg.jpg") no-repeat center / cover;
}
.animate-overlay{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 1;
}
.top-section__main {
  .main-line {
    width: 100%;
  }
}

.section-main {
  display: flex;
  flex-wrap: wrap;
  margin-top: em(-210);
  article {
    position: absolute;
    left: em(40);
    bottom: em(60);
  }
}

.bg-video__main {
  height: em(1090);
  .video-container {

  }
}
.overlay{
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    //background: url("/assets/img/overlay-main-video.png") no-repeat center / cover;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(45deg, rgba(#000,0.8) 0%,rgba(#000,0) 70%);
  }
}
.overlay_black{
  &:after {
    background: linear-gradient(45deg, rgba(#000,0.8) 0%,rgba(#000,0) 70%);
  }
}
.main-item {
  width: 50%;
  height: 44.938em;
  position: relative;
  padding: em(60) em(50);
}

.item_blue {
  background: linear-gradient(45deg, #b768fc 0%, #6075f9 100%);
}

.item_pink {
  background: linear-gradient(45deg, #f669c1 0%, #823da6 100%);
}

.item_red {
  background: linear-gradient(45deg, #ffc31c 0%, #f20465 100%);
}

.item_green {
  background: linear-gradient(45deg, #7ae04d 0%, #06bded 100%);
}

.main-item__icon {
  width: 25.75em;
  height: 23.75em;
  position: absolute;
  right: 2.625em;
  bottom: 20em;
  &.about-item__icon_packman{
    left: auto;
  }
}

.top-section {
  color: #fff;
  height: em(1090);
  position: relative;
  .wrapper {
    display: flex;
    //align-items: center;
    height: 100%;
  }
}

.main-line {
  width: 37.938em;
  height: 0.188em;
  background: linear-gradient(to right, #05bded 0%, #7be04c 100%);
}

.gradient-line {
  width: 100%;
  height: 0.188em;
  background: linear-gradient(to right, #05bded 0%, #7be04c 100%);
}

main {
  //max-width: em(1920);
  //width: 100%;
  //margin: auto;
  .main-line {
    margin-bottom: 3.1em;
  }
}

.top-section__content {
  margin-top: 19.5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.main-item__index{
  h4{
    transition: transform 500ms ease-in-out;
  }
  &:hover{
    h4{
      transform: scale(0.9);
    }
  }
}

.main-item__icon__about{
  width: 18.75em;
  height: 18.75em;
}
.main-item__icon__games{
  width: 18.563em;
  height: 19.25em;
}
.main-item__icon__career{
  width: 21.188em;
  height: 16.625em;
}
.main-item__icon-contacts{
  width: 18.688em;
  height: 18.438em;
}