//==============================================
// Mixins/GENERATE MEDIA QUERIES
//==============================================

// @requires - ../_functions.scss

@mixin media($conditions...) {
	@for $i from 1 through length($conditions) {
		$conditions: set-nth($conditions, $i, -w-parse-expression(nth($conditions, $i)));	
	}

	$concats: -w-concat($conditions);
	$mq: "";

	@each $concat in $concats {
	    @if (str-length($mq) != 0) {
	      $mq: $mq + ", ";
	    }
    	$mq: $mq + $concat;
	}

	 @media #{$mq} {
		@content;
	 }
}

