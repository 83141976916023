.item-contacts__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 8em;
}
.item-about__content-line{
  margin-top: 2.9em;
  max-width: 70%;
  a{
    color: #fff;
    text-decoration: none;
  }
}
.contacts-top__icon{
  width: 25.938em;
  height: 27.25em;
  position: relative;
  top: -5em;
  right: -3em;
}
.item-contacts{
  padding: em(40) em(50);
}
.contacts-item__icon_tower{
  width: 10.313em;
  height: 8.125em;
}
.contacts-item__icon_cherch{
  width: 7.25em;
  height: 9.375em;
}