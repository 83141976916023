//==============================================
// Utilites/Typography
//==============================================

$google-fonts-url:            "https://fonts.googleapis.com/css?family=Exo:300,400,600,700,800,900&subset=latin-ext";

// ==========================================================================
// If YOU NEED YOUR CUSTOM FONT - use @font-fase. Uncomment from line 17
// ==========================================================================
// $typefaces - {map}
// @family - {string}
// @weight - {integer} or {keyword} i.e. 800, bold
// @style - {keyword} i.e. normal, italic
// @sourse - https://github.com/minamarkham/sassy-starter/blob/master/src/scss/utilities/_fonts.scss
// @usage:

// $typefaces: (
//   'industry': (
//    family: 'industry',
//    weight: 100,
//    style: normal,
//   )
// );

// Scaling font size from h4 to h1
$scale:                       1.25 !default;

// $base-font-size
$font-size-base:              16px !default;
$font-size-medium:            ceil(($font-size-base * $scale)) !default;
$font-size-large:             ceil(($font-size-base * $scale * 1.5)) !default;
$font-size-small:             ceil(($font-size-base / $scale)) !default;

// $line-height
$line-height-base:            1 !default;
$line-height-medium:          1.6 !default;
$line-height-large:           1.8 !default;
$line-height-small:           1.5 !default;

// Round a number down
$line-height-count: floor(($font-size-base * $line-height-base)) !default;

// font family
$font-family-fallback:        'Arial', sans-serif;
$font-family-open:             'Circe';
$font-family-base:            $font-family-open;
$font-family-second:          $font-family-open;

$font-family-monospace:       'Inconsolata', 'Menlo', 'Consolas', 'Bitstream Vera Sans Mono', 'Courier', monospace;

@if variable-exists(google-fonts-url) {
  @import url($google-fonts-url);
}

@if variable-exists(typefaces) {
  @each $file, $option in $typefaces {
    @font-face {
      font-family: map-get(map-get($typefaces, $file), family);
      font-style: map-get(map-get($typefaces, $file), style);
      font-weight: map-get(map-get($typefaces, $file), weight);
      src: url('../fonts/#{$file}.ttf') format('truetype'),
           url('../fonts/#{$file}.woff') format('woff'),
           url('../fonts/#{$file}.woff2') format('woff2');
    }
  }
}