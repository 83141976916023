//==============================================
// Layout/header
//============================================== 

.header{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 8.563em;
	z-index: 10;
	.wrapper{
		height: 100%;
	}
}

.bloc-logo{
	width: 4.875em;
	height: 5.688em;
	display: inline-block;
	position: relative;
}
.logo-mask {
	position: absolute;
	top: 0;
	left: 0;
	background-size: 5.9em;
	background-image: url(/assets/img/pf_logo_black.png);
	background-position: -0.9em 0;
	background-repeat: no-repeat;
	text-indent: -9999px;
	width: 100%;
	height: 100%;
	z-index: 100;

}
#logo-canvas{
	position: absolute;
	display: block;
	width: 98%;
	height: 98%;
	top: 50%;
	bottom: 0;
	left: 50%;
	z-index: 10;
	transform: translate(-50%,-50%);
}
.header-nav{
	background-color: #000;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 em(40);
	ul{
		list-style: none;
		padding:0;
	}
}
.header-nav__item{
	ul{
		display: flex;
		align-items: center;
		a{
			color: #a8a8a8;
			font-size: 1.5em;
			font-weight: 300;
			line-height: 1.438em;
			text-decoration: none;
			transition: color 300ms ease-in-out;
			&:hover{
				color: #6075f9;
			}
		}
		.active-link{
			color: #6075f9;
			font-weight: 700;
		}
	}


}
.header-nav__right{
	li{
		margin-right: em(40);
		&:last-child{
			margin-right: 0;
		}
	}

}
.header-nav__left{
	display: flex;
	align-items: center;
	ul{
		margin-left: em(100);
	}
	li{
		margin-right: em(30);
	}
}
