@font-face {
  font-family: 'Circe Extra';
  src: url('/assets/fonts/subset-Circe-ExtraBold.eot');
  src: url('/assets/fonts/subset-Circe-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/subset-Circe-ExtraBold.woff2') format('woff2'),
  url('/assets/fonts/subset-Circe-ExtraBold.woff') format('woff'),
  url('/assets/fonts/subset-Circe-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/subset-Circe-ExtraLight.eot');
  src: url('/assets/fonts/subset-Circe-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/subset-Circe-ExtraLight.woff2') format('woff2'),
  url('/assets/fonts/subset-Circe-ExtraLight.woff') format('woff'),
  url('/assets/fonts/subset-Circe-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/subset-Circe-Regular.eot');
  src: url('/assets/fonts/subset-Circe-Regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/subset-Circe-Regular.woff2') format('woff2'),
  url('/assets/fonts/subset-Circe-Regular.woff') format('woff'),
  url('/assets/fonts/subset-Circe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/subset-Circe-Thin.eot');
  src: url('/assets/fonts/subset-Circe-Thin.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/subset-Circe-Thin.woff2') format('woff2'),
  url('/assets/fonts/subset-Circe-Thin.woff') format('woff'),
  url('/assets/fonts/subset-Circe-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/subset-Circe-Bold.eot');
  src: url('/assets/fonts/subset-Circe-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/subset-Circe-Bold.woff2') format('woff2'),
  url('/assets/fonts/subset-Circe-Bold.woff') format('woff'),
  url('/assets/fonts/subset-Circe-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Circe Extra';
  src: url('/assets/fonts/subset-Circe-ExtraLight.eot');
  src: url('/assets/fonts/subset-Circe-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/subset-Circe-ExtraLight.woff2') format('woff2'),
  url('/assets/fonts/subset-Circe-ExtraLight.woff') format('woff'),
  url('/assets/fonts/subset-Circe-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/subset-Circe-ExtraBold.eot');
  src: url('/assets/fonts/subset-Circe-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/subset-Circe-ExtraBold.woff2') format('woff2'),
  url('/assets/fonts/subset-Circe-ExtraBold.woff') format('woff'),
  url('/assets/fonts/subset-Circe-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/assets/fonts/subset-Circe-Light.eot');
  src: url('/assets/fonts/subset-Circe-Light.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/subset-Circe-Light.woff2') format('woff2'),
  url('/assets/fonts/subset-Circe-Light.woff') format('woff'),
  url('/assets/fonts/subset-Circe-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

