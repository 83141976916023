//==============================================
// Module/your custom reset
//==============================================

address {
	font-style: normal;
}

figure {
	margin: 0;
}

h1,h2,h3,h4,h5,h6{
	margin: 0;
}
ul{
	margin:0;
}