//==============================================
// Vendor/Build mobile first grid system
//==============================================

// Can be edited

// Container
@if($responsive-app) {
  .container {
    @include container-fixed;

    @media (min-width: $screen-sm-min) {
      width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
      width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
      width: $container-lg;
    }
  }
}@else {
  .container {
      @include container-fixed;
      width: $container-lg;
  }
}

.container-wide {
  @include container-fixed;
  max-width: $screen-lg-max;
}

.container-fluid {
  @include container-fixed;
}
.row {
  @include make-row;
}

@if($responsive-app) {
  @include make-grid-columns;
}

// Extra small grid
@include make-grid(xs);

@if($responsive-app) {
  // Small grid
  @media (min-width: $screen-sm-min) {
    @include make-grid(sm);
  }
  // Medium grid
  @media (min-width: $screen-md-min) {
    @include make-grid(md);
  }
  // Large grid
  @media (min-width: $screen-lg-min) {
    @include make-grid(lg);
  }
}
