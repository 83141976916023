.about-bg{
  background: url("/assets/img/about.jpg") no-repeat center / cover;
}
.bg_slider{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}
.map{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.section-about {
  display: flex;
  flex-wrap: wrap;
  margin-top: em(-210);
}
.about-item__icon{
  width: 19em;
  height: 15em;
  top: 0;
  display: block;
  margin-bottom: em(110);
  position: absolute;
  left: 3em;
  object,svg{
    height: 100%;
    width: 100%;
    fill:#fff;
    g,path{
      height: 100%;
      width: 100%;
      display: block;
    }

  }
}
.about-item__icon_packman{
  left: 1em;
}
.about-item__icon_icon2{
  left: 0;

}
.about-item__icon_icon3{
  left: 0;

}
.item-about{
  padding: 17em em(50);
  h5{
    color: #fff;
  }
}
.contacts-top__icon{
  width: 18.688em;
  height: 18.438em;
}
.top-section__about{
  .wrapper{
    justify-content: space-between;
  }
}
.item-about__content{

  p{
    font-size: 1.375em;
    font-weight: 300;
    color: #fff;
  }
  h5{
    font-size: 2.813em;
    font-weight: 300;
    color: #fff;
  }
}
