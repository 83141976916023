//==============================================
// Mixins/Reset
//==============================================

// Mixins
// Resets default list styling.

@mixin reset-list {
  list-style: none;
  margin: 0;
  padding: 0;
}