//==============================================
// Module/keyframes
//==============================================

// Animate your modules or other elements.

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}
