//==============================================
// Layout/footer
//============================================== 

.footer {
  padding: em(77) 0;
}
.footer__text{
  display: inline-block;
  margin-right: em(35);
  color: #000;
  font-size: 1.5em;
}