.career-top__icon {
  width: 25.938em;
  height: 27.25em;
  position: relative;
  top: -5em;
  right: -2em;
}
.item-career{
  overflow: hidden;
  min-height: em(805);
  height: auto;
  ul{
    display: none;
  }
  &[data-career="responsibility"]{
    [data-list-responsibility]{
      display: block;
    }
  }
  &[data-career="requirement"]{
    [data-list-requirement]{
      display: block;
    }
  }
}
.item-career__header{
  h4{
    color: black;
    font-weight: 700;
  }
  p{
    color: black;
    font-weight: 300;
    line-height: 1;
  }
}
.item-career__list {
  ul {
    list-style: none;
    padding-left: 0;
    max-width: 95%;
    li{
      position: relative;
      padding-left: 1.2em;
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: em(12);
        background-color: #000;
        top: 1.1em;
        left: 0;
      }
    }
    span{
      color: black;
      font-size: 1.75em;
      font-weight: 300;
      line-height: 1.4em;
    }
  }
}
.item-career__list_margin{
  margin-top: 3.6em;
}
.section-career {
  border: 1px solid #f1f1f1;
}

.item-career__nav {
  display: flex;

}
.item-career__nav_margin{
  margin-top: 3.5em;
}
.career-nav__line {
  position: relative;
  cursor: pointer;
  margin-right: em(65);
  &:last-child{
    margin-right: 0;
  }
  p {
    color: black;
    font-size: 1.375em;
    font-weight: 700;
    margin: 0;
    opacity: 0.29;
  }
  display: inline-block;
  &.active {
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 0.188em;
      background: linear-gradient(to right, #05bded 0%, #7be04c 100%);
      bottom: 0;
    }
    p {
      opacity: 1;
    }
  }
}

.career-section-form {
  padding: em(77) em(50);
  margin-top: em(55);
  article {
    margin-bottom: em(40);
  }
  .form__line {
    display: flex;
    justify-content: space-between;
  }
  h4 {
    color: #000;
  }
  input {
    width: 100%;
    height: 5.688em;
    background-color: white;
    border: 0.063em solid rgba(0, 0, 0, 0.07);
    padding: 0 em(15);
    //font-size: 1.1em;

  }

}

.form-group__half {
  max-width: 38.063em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.add-file {
  width: 14.063em;
  height: 13.188em;
  border: 0.063em solid rgba(0, 0, 0, 0.07);

  label{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }
  span,svg,object{
    pointer-events: none;
  }
  input {
    display: none;
  }
}

.icon__add-file {
  width: 4.5em;
  height: 5.125em;
  display: block;
  margin: 0 auto 0.4em;
}

.add-file__text {
  opacity: 0.49;
  color: black;
  font-size: 1.375em;
  font-weight: 300;
  display: block;
}

.career-btn {
  background-color: transparent;
  border: none;
  line-height: 1;
  span {
    color: black;
    font-size: 1.875em;
    font-weight: 700;
  }
}

.career-wrapper__btn {
  margin-top: em(85);
}

.icon__arrow {
  width: 1.688em;
  height: 1.063em;
  fill: #000
}

.form-group__input {
  position: relative;
}

.custom-placeholder {
  position: absolute;
  left: 0.7em;
  top: 0.7em;
  opacity: 0.49;
  color: black;
  font-size: 1.375em;
  font-weight: 300;
}