html {
  box-sizing: border-box;
  font-size: 1em;
  height: 100%;
}

* {
  box-sizing: border-box;
  &:before,
  &:after {
    @extend *;
  }
}

body {
  color: $color-text-main;
  font: #{$font-size-base}/#{$line-height-base} $font-family-base;
  overflow-x: hidden;
  text-rendering: optimizeLegibility; //The browser emphasizes legibility over rendering speed and geometric precision. This enables kerning and optional ligatures. https://developer.mozilla.org/en-US/docs/Web/CSS/text-rendering
  -webkit-font-smoothing: antialiased; //Make text look good on mac(Tim Van Damme test)
}

@include H($font-size-base, $scale);

a {
  background: transparent;
  //text-decoration: $link-hover-decoration;
  transition: color .2s;
  @include hover {
    color: $color-link-hover;
  }
}

p {
  font-weight: 400;
  line-height: $line-height-small;
  margin: 0;
}

small,
.small {
  color: $color-text-main;
  font-size: $font-size-small;
  line-height: $line-height-base;
}

strong {
  font-weight: 800;
}

b {
  font-weight: 600;
}

img {
  height: auto;
  max-width: 100%;
}

.wrapper {
  max-width: em(1600);
  margin: auto;
  width: 100%;
  position: relative;
  z-index: 5;
}

article {
  h2{
    font-size: 8.5em;
  }
  h4 {
    color: white;
    font-size: 4.5em;
  }
  h5{
    font-size: 3em;
  }
  h6{
    font-size: 2.25em;
  }
  p{
    font-size: 1.875em;
  }
}
.text_center{
  text-align: center;
}
.text_bold {
  font-weight: 700;
}

.text_medium {
  font-weight: 500;
}
.text_regular {
  font-weight: 400;
}
.text_light {
  font-weight: 300;
}
.bg_grey{
  background-color: #f1f1f1;
}
.bg_white{
  background-color: #fff;
}

.flex{
  display: flex;
}
.flex-horizontal_end{
  display: flex;
  justify-content: flex-end;
}
.text_none{
  text-decoration: none
}
.opacity_zero{
  opacity: 0;
}

.animate_translate_bottom{
  transform: translate(0,50%);
}
.animate_translate_top{
  transform: translate(0,-100%);
}

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 110%;
  min-height: 110%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.mt_0-5{
  margin-top: 0.5em;
}