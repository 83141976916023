.top-section__games{
  text-align: center;
  height: em(1680);
  position: relative;
  article{
    //max-width: em(1030);
    margin: 14em auto 0;
  }
}
.bg-video{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.bg-video__games{
  height: em(1310);
}
.game-scene{
  background: url("/assets/img/games/ferma.png") no-repeat center / contain;
  height: 57em;
  width: 100%;
  position: absolute;
  bottom: -1em;
  z-index: 1;
}
.icon__stay-alive{
  width: 21.125em;
  height: 19.125em;
  background: url("/assets/img/games/stay-alive.png") no-repeat center / contain;
  margin: auto;
  fill:#fff
}
.games-bg{
  background: url("/assets/img/games/bg-games.jpg") no-repeat center / cover;
}
.line-games_margin{
  margin: em(40) auto em(80);
}
.games__footer-text{
  position: absolute;
  bottom: -4em;
  left: 0;
  right: 0;
  p{
    color: black;
    font-size: 1.188em;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.section-games{
  display: flex;
  margin-top: 8em;
}
.item-games__slider{
  color: #fff;
  padding: 0;
  .swiper-slide{
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
  article{
    padding:0 0 em(100) em(55) ;
    max-width: 80%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
  }
  h5{
    margin-top: 0.5em;
  }
  .swiper-wrapper{
    height: 100%;
  }
  .swiper-pagination{
    text-align: left;
    padding-left: em(55);
    bottom: em(35);
  }
  .swiper-pagination-bullet{
    width: 1.25em;
    height: 1.25em;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: transparent;
    opacity: 1;
    margin-right: em(12);
  }
  .swiper-pagination-bullet-active{
    background-color: #fff;
  }
}
.cover__position-bg{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


