//==============================================
// Module/buttons
//============================================== 

.btn {
  @include m-btn-size;
  background-color: $btn-default-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: $b-s-base solid $btn-default-border;
  color: $btn-default-color;
  cursor: pointer;
  display: inline-block;
  font-weight: $btn-font-weight;
  line-height: $line-height-base;
  margin-bottom: 0;
  outline: none;
  text-align: center;
  touch-action: manipulation;
  transition: all $timeline-base !important;
  user-select:none;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: $padding-large-vertical $padding-large-horizontal;
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    opacity:.65;
    box-shadow:none;
  }
  @include hover {
    background-color: darken($btn-default-bg, 20%);
    border-color: darken($btn-default-bg, 20%);
    color: $white;
  }
  &.full {
    width: 100%;
  }
}