.icon {
	background: url("../img/svg/sprite.svg") no-repeat;
}

.icon-add-file {
	background-position: 0 0;
	width: 100px;
	height: 100px;
}

.icon-arrow {
	background-position: 0 5.613215755173981%;
	width: 31.49px;
	height: 31.49px;
}

.icon-chearch {
	background-position: 0 7.5927523727351165%;
	width: 57.9px;
	height: 74.5px;
}

.icon-cup {
	background-position: 0 11.827895548825783%;
	width: 79.3px;
	height: 62.9px;
}

.icon-facebook-example-icon {
	background-position: 0 20.7532667179093%;
	width: 512px;
	height: 512px;
}

.icon-packman {
	background-position: 0 46.299585553582%;
	width: 164px;
	height: 124px;
}

.icon-plane {
	background-position: 0 51.331444759206796%;
	width: 48px;
	height: 48px;
}

.icon-scary {
	background-position: 0 54.05099150141643%;
	width: 48px;
	height: 48px;
}

.icon-sprite {
	background-position: 0 76.31378522467631%;
	width: 52px;
	height: 500px;
}

.icon-stay-alive {
	background-position: 0 95.6018076506906%;
	width: 266.9px;
	height: 241.9px;
}

.icon-tower {
	background-position: 0 99.97706947947718%;
	width: 88px;
	height: 68.6px;
}

