
.desktop_hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 2vw !important;

    .opacity_zero{
      //opacity: 1;
    }

    .main-item {
      height: 25em;
      width: 25em;
      margin-top: -1px;
    }
    .overlay{
      &:after {
        background: linear-gradient(60deg, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 70%);
        //background: url("/assets/img/mobile/overlay_mobile.png") no-repeat center / cover;
      }
    }
    .overlay_black{
      &:after {
        background: linear-gradient(60deg, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 70%);
      }
    }
    .animate-overlay{
      z-index: 2;
    }

    .top-section {
      height: 50em;
      padding-top: em(95);
      position: relative;
      .wrapper {
        padding: 0 em(30);
        align-items: flex-start;
      }
    }
    .top-section__main{
      article{
        margin-top: 3em;
      }
      .main-line{
        width: 100%;
      }
    }
    .main-line{
      margin-bottom: 2em;
    }
    .section-main {
      margin-top: 0;
      display: flex;
      justify-content: center;
    }
    .main-slider__mobile{

    }
.bg-video__main{
  .video-container{
    display: none;
  }
}
    .main-slider__mobile{
      height: 100%;
    }

    .item-about, .item-contacts, .item-games__slider, .item-career {
      width: 100%;
      min-height: 55em;
      height: 100%;
      article {
        //max-width: 80%;
      }
    }

    .item-games__slider {
      height: 55em;
    }
    //about
    .about-item__icon {
      width: 12.375em;
      height: 12.375em;
      margin-bottom: 3em;
    }
    .about-item__icon_packman{
      width: 32em;
      height: 25em;
      left: -3em;
      top: -3em;
    }
    .about-item__icon_icon2{
      width: 32em;
      height: 25em;
      top: -3em;
      left: -2em;
    }
    .about-item__icon_icon3{
      width: 34em;
      height: 25em;
      top: -5em;
      left: -2em;
    }
    .item-about {
      padding: 19em 3.125em 4em;
      min-height: 37em;
      h5 {
        font-size: 3em;
        line-height: 1.4em;
      }
    }
    .item-about__content {
      margin-top: 2em;
      p {
        font-size: 2em;
      }
      h5 {
        font-size: 3.2em;
      }
    }
    .main-item__icon {
      top: em(50);
      right: em(60);
      font-size: 0.6em;
      bottom: auto;
    }
    .main-item__icon__career{
      top:5em;
    }
.contacts-item__icon{
  bottom: auto;
  top: 0;
  font-size: 0.8em;
}
    .contacts-item__icon_tower{
      width: 14.813em;
      height: 14em;
    }
    .contacts-item__icon_cherch{
      width: 10.813em;
      height: 14em;
    }
    .section-about {
      margin-top: 0;
    }

    //career
    .career-section-form {
      input {
        font-size: 2em;
        height: 4em;
        padding: 0 0.4em;
      }
      .form__line {
        display: block;
      }
    }
    .career-top__icon {
      position: absolute;
      right: 1.75em;
      bottom: 3em;
      top: auto;
      width: 17.688em;
      height: 17.438em;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .item-career__header {
      h4 {
        //font-size: 4.5em;
      }
    }
    .item-career__list ul span {
      font-size: 1.9em;
    }
    .form-group__half {
      margin: 0;
      max-width: 100%;
    }
    .form-group__input {
      margin-bottom: 2em;
    }
    .add-file {
      width: 100%;
      height: 12em;
      padding: 1em 0;
      label {
        //display: block;
        //text-align: center;
        flex-direction: row;
      }
    }
    .add-file__text {
      font-size: 1.6em;
      display: inline-block;
      vertical-align: middle;
    }
    .career-btn {
      span {
        font-size: 3em;
      }
    }
    .icon__arrow {
      width: 2.5em;
      height: 2.5em;
      fill: #000;
    }
    .custom-placeholder {
      font-size: 1.5em;
    }
    .section-career {
      border: none
    }
    .icon__add-file {
      margin: 0 1em 0 0 ;
      display: inline-block;
      vertical-align: middle;
    }
    .career-wrapper__btn {
      justify-content: center;
    }

    //end career

    .contacts-top__icon {
      position: absolute;
      right: 1.75em;
      bottom: 3em;
      top: auto;
      width: 17.688em;
      height: 17.438em;
    }

    .top-section__content {
      margin-top: 0;
    }

    //games
    .top-section__games {
      //margin-bottom: 12em;
      article {
        margin: -2em auto;
        p {
          font-size: 2.1em;
          line-height: 1.2em;
          br {
            display: none;
          }
        }
      }
    }
    .section-games {
      display: block;
      margin-top: 21em;
    }
    .games__footer-text {
      bottom: -19em;
      p {
        font-size: 2em;
      }
    }
    .bg-video__games {
      height: 100%;
    }
    .bg-video__main{
      height: 100%;
    }
    .game-scene {
      height: 25em;
      bottom: -14em;
    }
    //end games

    .footer {
      display: none;
    }
    article {
      h4 {
        //font-size: 4em;
      }
      h5 {
        //font-size: 4.25em;
      }
    }

    ///navigation
    .header {
      height: 11em;
      position: relative;
      transform: translate(0,-200%);
      &.menu__show {
        .header-nav__right {
          transform: translate(0, 0);
          ul,.header-nav__item__mobile{
            opacity: 1;
          }
        }
        .mobile-menu__btn {
          background: url("/assets/img/mobile/mobile-menu_close.png") no-repeat center / contain;
        }
      }
    }
    .bloc-logo{
      width: 7.8em;
      height: 7.8em;
      #logo-canvas{
        width: 99%;
        height: 99%;
      }
      .logo-mask{
        background-size: 8em;
        background-repeat: repeat;
      }
    }
    .header-nav__left {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 10;
      background-color: #000;
      padding: 1.7em 2em;
      justify-content: space-between;
      ul {
        margin: 0;
        a {
          font-size: 3.4em;
        }
      }
      //bottom: 0;
    }
    .header-nav__right {
      position: absolute;
      top: 99%;
      left: 0;
      right: 0;
      background-color: #000;
      padding: 3em 3em 5em 3em;
      transition: transform 300ms ease-in-out;
      transform: translate(0, -110%);
      ul,.header-nav__item__mobile{
        opacity: 0;
      }
      ul {
        display: block;
        li {
          text-align: center;
          margin-right: 0;
        }
        a {
          font-size: 5em;
          color: #fff;
          &.active-link {
            font-weight: 700;
          }
        }
      }
    }
    .header-nav__item__mobile {
      text-align: center;
      margin-top: 5em;
      a, p {
        font-size: 2.3em;
        font-weight: 400;
        color: #fff;
        line-height: 1.6em;
        display: block;
      }
    }
    .mobile-menu__btn {
      width: 5em;
      height: 5em;
      background: url("/assets/img/mobile/mobile-menu.png") no-repeat center / contain;

    }
    .desktop_hidden {
      display: block;
    }

  }

}
